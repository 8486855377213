// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import { CodeBlock } from "../../../components/widgets/codeBlock"
import { catalogoScripts } from "../../../scripts/catalogo"
import SectionNavigate from "../../../components/widgets/sectionNavigate"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ApiCfdiMinimo: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isApi={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ApiDocs' className="_DOCS withSide">
            <div className="content">
                <h2>Catálogo de estados para complemento de notarios</h2>
                <p>
                    Usado en el complemento de notarios, donde se solicite los estados.
                </p>
                <pre>
                    <code>
                        <b>GET:</b> https://api.fiscalpop.com/api/v1/sat/estados/<b><span className="error">:authToken</span></b>
                    </code>
                </pre>
                <h3>
                    Campos de la Respuesta
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>clave: </b> Clave del estado.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>nombre:</b> El nombre del estado de la república.
                        </p>
                    </li>
                </ul>
                <SectionNavigate next={`/api/catalogo/catalogo-inmuebles`} previous={`/api/catalogo/catalogo-paises`}/>
            </div>
            <div className="side">
                <CodeBlock title="Estados del país" requests={catalogoScripts.estados.requests} response={catalogoScripts.estados.response} />
            </div>
        </div>
    </Layout>
)

export default ApiCfdiMinimo
